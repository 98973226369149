/* You can add global styles to this file, and also import other style files */
@import url('https://use.typekit.net/aqo5uml.css');
@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css');
@import url('https://use.fontawesome.com/releases/v5.7.0/css/all.css');
@import '../../theme/styles';
@import '../../theme/_responsive';

$accent: #14a4e6;
$secondary: #e4e7eb;
$info: #17a2b8;
$success: #1b7a47;
$warning: #f79e00;
$error: #ee5726;
/*$body-bg: #ffffff;*/
$body-color: #1b1a1a;
$component-bg: #ffffff;
$component-color: #1b1a1a;
$card-cap-bg: #f7f7f9;
$card-cap-color: #1b1a1a;
$series-a: #14a4e6;
$series-b: #5bc0de;
$series-c: #5cb85c;
$series-d: #f0ad4e;
$series-e: #e67d4a;
$series-f: #d9534f;


@import "../node_modules/@progress/kendo-theme-bootstrap/dist/all.scss";

#content > div > app-dashboard > ngu-carousel > div > div.ngu-touch-container > div > div:nth-child(1) > div > div > app-dashboard-bids > div > div > div > div.m-b-10.ng-star-inserted > div > div.m-b-25.ng-star-inserted > div > div > kendo-grid > div
{
display: flex;
flex-wrap: nowrap;
overflow-x: auto;
}

body, .header {
  padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
  padding-top: env(safe-area-inset-top); /* iOS 11.2 */
}
.footer {
  margin-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
  margin-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
}
.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.overflow-scroll {
  overflow: scroll;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.sidebar .sub-menu {
  display: block;
}
.page-sidebar-minified .sidebar .sub-menu {
  display: none;
}
.page-sidebar-minified .sidebar .nav > li {
  z-index: 1020;
}
.mobile-scroll {
  overflow: scroll;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* Styling for the Slim Loading Progress Bar container */
.mat-progress-bar {
  position: fixed;
  margin: 0;
  padding: 0;
  top: 70px;
  left: 0;
  right: 0;
  z-index: 99999;
}
.top .mat-progress-bar {
  top: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #0aaa64; //progress bar color
  --mdc-linear-progress-track-color: transparent; // background color
}

/* header */
.header .navbar-nav>li>.dropdown-menu {
  position: absolute;
}

.dropdown-menu-right.dropdown-menu {
  right: 0 !important;
  left: auto !important;
}

/* Sidebar Minified Menu */
.page-sidebar-minified .sidebar .nav li:hover > div > .sub-menu {
  display: block;
  position: fixed;
  margin-left: 60px;
  margin-top: -40px;
  width: 220px;
  background: #242a31;
  z-index: 1020;
  padding: 9px 0 9px 39px;
}
.page-sidebar-minified .sidebar .nav .sub-menu li {
  position: relative;
}
.page-sidebar-minified .sidebar .nav .sub-menu li:hover > div > .sub-menu {
  margin: 0px;
  left: 100%;
  top: -9px;
  position: absolute;
}
.page-sidebar-minified .sidebar .sub-menu > li:first-child:before {
  top: 11px;
  height: 2px;
  width: 30px;
  left: -39px;
}
.page-sidebar-minified .sidebar .sub-menu > li:first-child + li:before {
  top: -10px;
}
.page-sidebar-minified .sidebar .sub-menu > li:first-child + li:last-child:before {
  height: auto;
  bottom: 10px;
}
.page-sidebar-minified .sidebar .nav .sub-menu > li > a .caret {
  position: initial;
  margin: 0;
}
.page-sidebar-minified .sidebar .sub-menu > li:before,
.page-sidebar-minified .sidebar .sub-menu > li > a:after,
.page-sidebar-minified .sidebar .sub-menu > li.has-sub > a:before {
  background: #2d353c;
}

.sidebar .overflow-scroll::-webkit-scrollbar {
  width: 6px;
}

.sidebar .overflow-scroll::-webkit-scrollbar-track {
  background-color: rgba(228, 231, 235, 0.25);
}
.sidebar .overflow-scroll::-webkit-scrollbar-track:horizontal {
  background: transparent;
}

.sidebar .overflow-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(255,255,255,0.25);
  border-radius: 6px;
  width: 6px;
}

.sidebar .overflow-scroll::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255,255,255,0.5);
}


.cookie-banner--inner {
  padding:15px;

  .cookie-banner--text {
    font-size: 15px;

    a {
      text-decoration: underline;
    }
  }

  .cookie-banner--button-group {
    button {
      min-width: 160px;
    }
  }
}


@media(max-width:991.5px) {
  .cookie-banner--text {
    font-size: 13px;
  }
}

.page-header-fixed.page-with-cookie-policy {
  padding-top: 145px;

  @media (min-width: 768px) and (max-width:991.5px) {
    padding-top: 170px;
  }

  @media (min-width: 576px) and (max-width:767.5px) {
    padding-top: 255px;
  }

  @media (max-width:575.5px) {
    padding-top: 270px;
  }

  .sidebar {
    padding-top: 145px;

    @media (min-width: 768px) and (max-width:991.5px) {
      padding-top: 150px;
    }
  }
}


.header {
  .navbar-brand {
    @media (min-width: 768px) {
      width: 235px;
    }

    img {
      /*width: 140px;
            height: auto;*/
    }
  }

  .navbar-back {
    color: rgba(255, 255, 255, 0.75);
    padding: 10px 15px;
  }

  .navbar-nav {
    > li {
      > a, .dropdown-toggle {
        padding: 15px 9px;

        @media (min-width: 992px) and (max-width:1199px) {
          padding: 15px 3px;
          font-size: 12px;
        }
      }
    }
  }

  > .navbar-right {
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .navbar-accounts {
    padding: 15px 9px;
    width: 300px;

    @media (max-width: 767px) {
      padding: 15px 0px;
      width: 80%;
      width: calc(100% - 75px);
    }

    @media (min-width: 768px) and (max-width:991px) {
    }

    @media (min-width: 992px) and (max-width:1199px) {
      padding: 15px 0px;
    }

    @media (min-width: 1200px) {
    }

    .k-dropdown {
      font-size: 14px !important;
      padding: 5px !important;
    }

    .k-dropdownlist > button {
      font-size: 14px !important;
      padding: 0 !important;
      background: transparent !important;
      border-width: 0px;
      float: right;
    }

    .k-dropdownlist {
      background: #e4e7eb;
      color: #1c1c1c;
      padding: 10px !important;
    }
  }
}

// kendo-popup elements for kendo-dropdownlist
.k-list-ul {
  padding-inline-start: 0 !important;
  margin-bottom: 0 !important;
  padding: 10px 20px 10px 20px !important;
}

.k-list-ul, .k-list-item {
  list-style-type: none !important;
  list-style: none !important;
}

.k-list-ul:hover {
  background: #007bff;
  border-radius: 2px !important;
}


.footer {
  .footer-logo {
    width: 100%;
    max-width: 140px;
    height: auto;
    padding: 10px;
  }
}


.btn.btn-no-box-shadow,
.btn.btn-no-box-shadow:hover {
  box-shadow: none;
}

.action-filter{
  cursor:pointer;
}

.k-widget {
  font-size: 14px;
}

.k-grid td a {
  color: $link_color;
}

.k-grid-header .k-header {
  font-weight: 600;
}

.k-grid-gavilon.k-grid .k-grid-header {
  background: none;
}

.k-grid-gavilon.k-grid th.k-th-responsive {
  font-size: 18px;
  font-weight: 500;
}

.k-grid .img-commodity-icon,
.table .img-commodity-icon {
  width: 40px;
  height: auto;

  @media (max-width: 767px) {
    width: 25px;
  }
}

.k-grid-gavilon.k-grid {
  border: 0;
}

.k-grid-gavilon.k-grid .k-grid-header-wrap {
  border: 0;
}

.k-grid-gavilon.k-grid th {
  white-space: normal;
  border-width: 0 0 1px 0;
}

.k-grid-gavilon.k-grid td {
  border-width: 0 0 1px 0;
}

.k-grid-gavilon.k-grid td table.table-borderless td {
  border: none;
}

.k-grid-gavilon .k-grid-content tr:last-child .table td {
  border-bottom-width: 1px;
}

.k-grid-gavilon .k-alt {
  background-color: rgba(0,0,0,.05);
}

.k-grid-gavilon .k-alt td {
  background-color: #f5f5f5;
}

.k-grid-gavilon.k-grid tbody tr:hover {
  background-color: inherit;
}

.k-grid-gavilon.k-grid td {
  text-overflow: unset;
}

.k-grid-gavilon .k-alt td table.bg-none td {
  background: none !important;
}

.k-grid-gavilon.k-locations-bid.k-grid tr {
  border-color: #ccc
}


.k-grid-gavilon.k-locations-bid.k-grid td {
  border-width: 0 0 0 0;
}

.k-table {
  font-size: 14px !important;
}

.k-grid {
  font-size: 14px !important;
}

.k-locations-bid .k-alt td {
  background-color: #fff;
}

.k-expander-title {
  color: #000;
}

kendo-expansionpanel.k-state-disabled > div.k-expander-header > span.k-expander-indicator {
  display: none;
}

@media(min-width:768px) {
  .k-locations-bid .k-hierarchy-cell, .k-locations-bid .k-hierarchy-cell.k-header {
    visibility: hidden;
  }

  .k-grid.k-locations-bid .k-hierarchy-col {
    width: 0;
  }

  .k-locations-bid .k-detail-cell {
    display: none;
  }
}


@media(max-width:767.5px) {
}




.td-chart-dash {
  width: 180px;
  max-width: 40%;


  @media (max-width: 767px) {
    width: 150px;
    max-width: 30%;
  }
}

.searchWrapper {
  position: relative;
}


.searchSpinnerWrapper {
  min-height: 60px;

  .searchSpinner {
    text-align: center;
    font-size: 35px;
    position: absolute;
    min-height: 50px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color:#45503d;
    background: rgba(108, 117, 125, 0.5);

    i {
      opacity: 1;
      top: 15%;
      position: relative;
    }
  }
}
